<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert v-show="formIsSubmitted" transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px" color="success" type="success">
      Successfully updated!
    </v-alert>

    <v-alert v-show="formIsError" transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px" color="error" type="error">
      An error occurred!
    </v-alert>

    <v-row v-if="!pageIsLoading">
      <v-col class="d-flex align-center">
        <v-icon class="grey--text text-h4 mr-5" title="Go back to kanban stage" @click="
          $router.push({
            path: `/dashboard/admin/kanban/manage_stages/${type}`,
          })
        ">
          mdi-arrow-left-circle
        </v-icon>

        <label class="text-h4">Stage Update</label>
      </v-col>
    </v-row>

    <v-row v-if="!pageIsLoading">
      <v-col>
        <p class="grey--text">
          Identify the need for a new stage: Determine if a new stage is needed
          based on the process flow and the current status of tasks on the
          board. This could include a bottleneck in the workflow, a new phase in
          the project, or a change in the process.
        </p>
      </v-col>
    </v-row>

    <v-form v-if="!pageIsLoading" v-model="formIsValid" @submit.prevent="update">
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-text-field v-model="stageName" :rules="[rules.required]" label="Stage Name" hide-details="auto"
                outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea v-model="description" label="Description (optional)" hide-details="auto" outlined />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card v-for="(objectiveField, index) in sortedObjectiveFields" :key="index" class="mb-5 rounded-lg">
            <v-card-title>
              Objective No. {{ objectiveField.order }}

              <v-spacer />

              <v-icon @click="
                kbn_stg_objs.splice(
                  kbn_stg_objs.findIndex(
                    (el) => el.order === objectiveField.order
                  ),
                  1
                )
              ">
                mdi-close
              </v-icon>
            </v-card-title>

            <v-divider />

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="objectiveField.title" :rules="[rules.required]" hide-details="auto"
                      label="Title" outlined />

                    <v-text-field v-model="objectiveField.day_limit" :rules="[rules.required]" class="mt-9"
                      hide-details="auto" label="Day Limit" type="number" outlined />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-textarea v-model="objectiveField.description" hide-details="auto" label="Description" outlined />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="objectiveField.order" :rules="[rules.required]" hide-details="auto"
                      label="Order" type="number" outlined />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select v-model="objectiveField.predecessor" :items="objective_predecessors(index)" 
                      hide-details="auto" label="Predecessor" outlined />
                  </v-col>
                </v-row>

                <v-card v-for="(task, index) in objectiveField.kbn_stg_tsks" :key="index" class="my-5 rounded-lg"
                  style="background-color: #111">
                  <v-card-title>
                    Task No. {{ task.order }}

                    <v-spacer />

                    <v-icon @click="
                      objectiveField.kbn_stg_tsks.splice(index, 1)
                    ">
                      mdi-close
                    </v-icon>
                  </v-card-title>

                  <v-divider />

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="task.title" :rules="[rules.required]" hide-details="auto" label="Title"
                            outlined />

                          <v-text-field v-model="task.day_limit" :rules="[rules.required]" class="mt-9"
                            hide-details="auto" label="Day Limit" type="number" outlined />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-textarea v-model="task.description" hide-details="auto" label="Description" outlined />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field v-model="task.order" :rules="[rules.required]" hide-details="auto" label="Order"
                            type="number" outlined />
                        </v-col>

                        <v-col>
                          <v-select v-model="task.predecessor" :items="task_predecessors(objectiveField.order, index)" 
                            hide-details="auto" label="Predecessor" outlined />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-select v-model="task.selected_assignees" :items="assignees" item-value="id"
                            item-text="username" :rules="[rules.required]" label="Assignee" multiple outlined />
                        </v-col>
                      </v-row>

                      <v-card v-for="(helper, index) in task.kbn_stg_hlps" :key="index" class="mb-5 rounded-lg">
                        <v-card-title>
                          helper No.
                          {{ index + 1 }}

                          <v-spacer />

                          <v-icon @click="task.kbn_stg_hlps.splice(index, 1)">
                            mdi-close
                          </v-icon>
                        </v-card-title>

                        <v-divider />

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-text-field v-model="helper.title" :rules="[rules.required]" hide-details="auto"
                                  label="Title" outlined />

                                <v-select v-model="helper.component" :items="components" :rules="[rules.required]"
                                  class="mt-9" hide-details="auto" label="Component" outlined />
                              </v-col>

                              <v-col cols="12" sm="6">
                                <v-textarea v-model="helper.description" hide-details="auto" label="Description"
                                  outlined />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-container>

                    <v-divider />
                    <v-card-actions>
                      <v-row>
                        <v-col>
                          <v-btn :disabled="
                            objectiveField.title === '' || task.title === ''
                          " @click="addHelper(objectiveField.title, index)">
                            <v-icon class="mr-2 text-button"> mdi-plus </v-icon>
                            add helper
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-container>

              <v-divider />
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn :disabled="objectiveField.title === ''" @click="addTask(index)">
                      <v-icon class="mr-2 text-button"> mdi-plus </v-icon>
                      add task
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn :disabled="stageName === ''" @click="addObjective">
            <v-icon class="mr-2 text-button"> mdi-plus </v-icon>
            add objective
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn :disabled="!formIsValid || formIsSubmitting" type="submit">
            <v-progress-circular v-show="formIsSubmitting" class="mr-2" color="primary" indeterminate />
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import API from '@/plugins/API';

export default {
  props: ['type', 'id'],

  data: () => ({
    pageIsLoading: true,
    formIsValid: false,
    formIsSubmitting: false,
    formIsSubmitted: false,
    formIsError: false,

    stageName: '',
    description: '',

    assignees: [],

    components: ['component 1', 'component 2', 'component 3'],

    kbn_stg_objs: [],

    stageData: [],

    rules: {
      required: (value) => !!value || 'Required.',
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail.';
      },
      phone: (value) =>
        (value && value.length == 10) ||
        'Phone number must be 10 digits no spaces or characters (format 7803334444)',
    },
  }),

  computed: {
    sortedObjectiveFields() {
      const res = [...this.kbn_stg_objs];

      res.sort((a, b) => a.order - b.order);

      return res;
    },
  },

  methods: {
    
    objective_predecessors(index){
      const res = [ { value: 0, text: 'None' } ];

      if(this.sortedObjectiveFields.length > 1){
        // filter all objectives except for the current one
        const objectives = this.sortedObjectiveFields.filter((el, i) => i !== index);

        // loop through all objectives
        objectives.forEach((objective) => {
          res.push({
            text: objective.title,
            value: Number(objective.order),
          });
        });
      }

      return res;
    },

    task_predecessors(objective_order, task_index){
      const objective_index = this.sortedObjectiveFields.findIndex((el) => el.order === objective_order);

      const res = [ { value: 0, text: 'None' } ];

      if(this.sortedObjectiveFields[objective_index].kbn_stg_tsks.length > 1){
        // filter all objectives except for the current one
        const tasks = this.sortedObjectiveFields[objective_index].kbn_stg_tsks.filter((el, i) => i !== task_index);

        // loop through all objectives
        tasks.forEach((task) => {
          res.push({
            text: task.title,
            value: Number(task.order),
          });
        });
      }

      return res;
    },


    addObjective() {
      this.kbn_stg_objs.push({
        title: '',
        description: '',
        day_limit: null,
        order: this.kbn_stg_objs.length + 1,
        predecessor: '',

        kbn_stg_tsks: [],
      });
    },

    addTask(index) {
      this.sortedObjectiveFields[index].kbn_stg_tsks.push({
        title: '',
        description: '',
        day_limit: null,
        order: this.sortedObjectiveFields[index].kbn_stg_tsks.length + 1,
        predecessor: '',
        selected_assignees: [],

        kbn_stg_hlps: [],
      });
    },

    addHelper(objective_title, task_index) {
      const objective_index = this.sortedObjectiveFields.findIndex(
        (el) => el.title === objective_title
      );

      this.sortedObjectiveFields[objective_index].kbn_stg_tsks[
        task_index
      ].kbn_stg_hlps.push({
        title: '',
        description: '',
        component: null,
      });
    },

    async update() {
      try {
        this.formIsSubmitting = true;

        const res = await API().patch('api/kanban/update_stage', {
          stage_id: Number(this.id),
          stage_name: this.stageName,
          description: this.description,
          kbn_stg_objs: this.kbn_stg_objs,
          type: this.type
        });

        if (res.status === 201) console.log(res);

        // update the existing stage_items status to archived and add a new stage_items
        if(this.type === 'Deal') {
          const get_kanban_deal_stages_res = await API().get(`api/kanban/get_kanban_deal_data`);
          const deal_stages = get_kanban_deal_stages_res.data;

          deal_stages.forEach((deal_stage) => {
            if (deal_stage.id === Number(this.id)) {
              deal_stage.deals.forEach(async (deal) => {
                const update_stage_item_res = await API().post('api/kanban/update_kanban_stage_item', {
                    stage_id: deal_stage.id,
                    item_id: deal.id,
                    type: this.type,
                    choice: 'Existing',
                  });

                if(update_stage_item_res.status === 201) console.log(`stage item for: ${deal.public_name} was updated`);
              });
            }
          });
        }
        
        if(this.type === 'Lender on boarding') {
          const get_kanban_lender_stages_res = await API().get(`api/kanban/get_kanban_lender_data`);
          const lender_stages = get_kanban_lender_stages_res.data;

          lender_stages.forEach((lender_stage) => {
            if (lender_stage.id === Number(this.id)) {
              lender_stage.companies.forEach(async (company) => {
                const update_stage_item_res = await API().post('api/kanban/update_kanban_stage_item', {
                    stage_id: lender_stage.id,
                    item_id: company.id,
                    type: this.type,
                    choice: 'Existing',
                  });

                if(update_stage_item_res.status === 201) console.log(`stage item for: ${company.name} was updated`);
              });
            }
          });
        }

        this.formIsSubmitted = true;

        setTimeout(() => {
          this.formIsSubmitted = false;
          this.formIsSubmitting = false;
        }, 2000);

        setTimeout(() => {
          this.$router.push({
            path: `/dashboard/admin/kanban/manage_stages/${this.type}`,
          });
        }, 3000);
      } catch (error) {
        console.log(error);
        this.formIsError = true;
        setTimeout(() => {
          this.formIsError = false;
        }, 2000);

        this.formIsSubmitting = false;
      }
    },

    async readStage() {
      try {
        const res = await API().get(
          `api/kanban/read_kanban_stage?id=${this.id}`
        );
        
        this.stageName = res.data.stage_name;

        this.description = res.data.stage_description;

        this.kbn_stg_objs = res.data.kbn_stg_objs;

        const res_users = await API().get('api/kanban/get_internal_users');

        [this.assignees] = [res_users.data];

        console.log(res.data)

        this.pageIsLoading = false;
      } catch (err) {
        console.log(err);
        this.formIsError = true;
        setTimeout(() => {
          this.formIsError = false;
        }, 2000);

        this.formIsSubmitting = false;
      }
    },
  },

  mounted() {
    this.readStage();
  },
};
</script>
